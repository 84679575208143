<template>
  <div>
    <div class="home_top">
      <div class="home_left">
        <templateYear @refreshYear="refreshYear" />
      </div>
    </div>

    <div class="socialDev">
      <hydroProTop ref="hydroProTop" @changeFaOrgId="changeFaOrgId" />
    </div>
    <div class="socialDev">
      <hydroProBottom ref="hydroProBottom" />
    </div>
  </div>
</template>

<script>
import hydroProTop from '@/views/postImmigrationService/HomePage/hydropowerProjects/components/hydroProTop/index.vue'
import hydroProBottom from '@/views/postImmigrationService/HomePage/hydropowerProjects/components/hydroProBottom/index.vue'
import templateYear from '@/components/templateView/templateYear.vue'

export default {
  name: 'HcYmhfIndex',

  data() {
    return {}
  },
  components: {
    hydroProTop,
    hydroProBottom,
    templateYear
  },

  methods: {
    refreshYear(value, years) {
      this.$nextTick(() => {
        this.$refs.hydroProTop.setYears(value, years)
        this.$refs.hydroProBottom.setYears(value, years)
      })
    },
    changeFaOrgId(val) {
      console.log(val, '=========父组件changeOrgId======')

      this.$refs.hydroProBottom.receiveId(val)
    }
  }
}
</script>

<style lang="scss" scoped>
.home_top {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.socialDev {
  width: 100%;
  border-radius: 0.05rem;
  background-color: #fff;
  margin-top: 10px;
  padding: 0.125rem;
}
</style>
